<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <div class="row">
                        <div class="col-lg-12">
                            <!--begin::Card-->
                            <div class="card card-custom gutter-b example example-compact">
                                <div class="card-header">
                                    <h3 class="card-title">Coach Signup</h3>
                                </div>
                                <!--begin::Form-->
                                <form class="form" novalidate="novalidate" id="kt_save_changes_form">
                                    <div class="card-body">
                                        <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Personal Info:</h3>
                                        <div class="mb-15">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>First Name: <span class="text-danger">*</span></label>
                                                    <input type="text" name="firstname" ref="firstname"
                                                        class="form-control" placeholder="Enter the first name"
                                                        v-model="form.firstname" />
                                                    <span class="form-text text-dark">Please enter the first
                                                        name</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Last Name: <span class="text-danger">*</span></label>
                                                    <input type="text" name="lastname" ref="lastname"
                                                        class="form-control" placeholder="Enter the last name"
                                                        v-model="form.lastname" />
                                                    <span class="form-text text-dark">Please enter the last name</span>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Email: <span class="text-danger">*</span></label>
                                                    <input type="email" name="email" ref="email" class="form-control"
                                                        placeholder="Enter the email address" v-model="form.email"
                                                        autocomplete="off" />
                                                    <span class="form-text text-dark">Please enter the email
                                                        address</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Password: <span class="text-danger">*</span></label>
                                                    <input type="password" name="password" ref="password"
                                                        class="form-control" placeholder="Enter the password"
                                                        v-model="form.password" autocomplete="off" />
                                                    <span class="form-text text-dark">If the account already exists
                                                        this will
                                                        be ignored</span>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Location:</label>
                                                    <div class="input-group">
                                                        <input type="text" name="city" ref="city" class="form-control"
                                                            placeholder="Denver, CO, USA" v-model="form.city" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">
                                                                <i class="la la-map-marker"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span class="form-text text-dark">Please enter the city, state and
                                                        country.</span>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <div class="image-input image-input-outline" id="kt_profilephoto">
                                                        <label class="mb-5">Profile Image:</label>
                                                        <div class="image-input-wrapper"
                                                            :style="{ backgroundImage: `url(${profilephoto})`, width: `200px`, height: `200px` }">
                                                        </div>
                                                        <label class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                    mt-35
                                                " data-action="change" data-toggle="tooltip" title=""
                                                            data-original-title="Change profile photo">
                                                            <i class="fa fa-pen icon-sm text-dark"></i>
                                                            <input type="file" name="profilephoto"
                                                                accept=".png, .jpg, .jpeg"
                                                                @change="onProfileFileChange($event)" />
                                                            <input type="hidden" name="profile_profilephoto_remove" />
                                                        </label>
                                                        <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="cancel" data-toggle="tooltip"
                                                            title="Cancel profile photo">
                                                            <i class="ki ki-bold-close icon-xs text-dark"></i>
                                                        </span>
                                                        <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="remove" data-toggle="tooltip"
                                                            title="Remove profile photo" @click="current_profilephoto = null; form.profilephoto = null;
                                                            ">
                                                            <i class="ki ki-bold-close icon-xs text-dark"></i>
                                                        </span>
                                                    </div>
                                                    <span class="form-text text-dark mt-5">Allowed file types: png,
                                                        jpg,
                                                        jpeg.</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="image-input image-input-outline" id="kt_coachphoto">
                                                        <label class="mb-5">Coach Image:</label>
                                                        <div class="image-input-wrapper"
                                                            :style="{ backgroundImage: `url(${coachphoto})`, width: `371px`, height: `214px` }">
                                                        </div>
                                                        <label class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                    mt-35
                                                " data-action="change" data-toggle="tooltip" title=""
                                                            data-original-title="Change coach photo">
                                                            <i class="fa fa-pen icon-sm text-dark"></i>
                                                            <input type="file" name="coachphoto"
                                                                accept=".png, .jpg, .jpeg"
                                                                @change="onCoachFileChange($event)" />
                                                            <input type="hidden" name="profile_coachphoto_remove" />
                                                        </label>
                                                        <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="cancel" data-toggle="tooltip"
                                                            title="Cancel coach photo">
                                                            <i class="ki ki-bold-close icon-xs text-dark"></i>
                                                        </span>
                                                        <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="remove" data-toggle="tooltip" title="Remove coach photo"
                                                            @click="current_coachphoto = null; form.coachphoto = null;
                                                            ">
                                                            <i class="ki ki-bold-close icon-xs text-dark"></i>
                                                        </span>
                                                    </div>
                                                    <span class="form-text text-dark mt-5">Allowed file types: png,
                                                        jpg,
                                                        jpeg.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="font-size-lg text-dark font-weight-bold mb-6">2. Coaching Community:
                                        </h3>
                                        <div class="mb-15">
                                            <div class="form-group">
                                                <label for="exampleTextarea">Coach Bio <span
                                                        class="text-danger">*</span></label>
                                                <textarea class="form-control" name="bio" ref="bio" id="bio" rows="3"
                                                    v-model="form.bio"></textarea>
                                                <span class="form-text text-dark">Use \n for new lines or breaks</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="exampleTextarea">Coaching Community Description <span
                                                        class="text-danger">*</span></label>
                                                <textarea class="form-control" name="description" ref="description"
                                                    id="description" rows="3" v-model="form.description"></textarea>
                                                <span class="form-text text-dark">Use \n for new lines or breaks</span>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Coaching Community Title: <span
                                                            class="text-danger">*</span></label>
                                                    <input type="text" name="title" ref="title" class="form-control"
                                                        placeholder="Enter the title" v-model="form.title" />
                                                    <span class="form-text text-dark">Please enter the title</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Coaching Community Category: <span
                                                            class="text-danger">*</span></label>
                                                    <b-form-select v-model="form.category" :options="categoryOptions"
                                                        class="form-control" name="category" ref="category">
                                                    </b-form-select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Coaching Community Keywords:</label>
                                                    <b-form-tags ref="keywords" name="keywords" input-id="tags-basic"
                                                        v-model="form.keywords" class="form-control"></b-form-tags>
                                                    <span class="form-text text-dark">Please enter the keywords and
                                                        press
                                                        enter</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Preview Video: <span class="text-danger">*</span></label>
                                                    <input type="text" name="video" ref="video" class="form-control"
                                                        placeholder="Enter the video url" v-model="form.video" />
                                                    <span class="form-text text-dark">Must be an m3u8 file and uploaded
                                                        to
                                                        Amazon S3 ingomucdn bucket.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="font-size-lg text-dark font-weight-bold mb-6">3. Schedule:</h3>
                                        <div class="mb-15">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Livestream Time: <span class="text-danger">*</span></label>
                                                    <input type="text" name="livestreamtimes" ref="livestreamtimes"
                                                        class="form-control" placeholder="Enter the livestream times"
                                                        v-model="form.livestreamtimes" />
                                                    <span class="form-text text-dark">This is a string representation
                                                        (i.e, 4th
                                                        Tuesday at 3:00pm Eastern Time) </span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Day of the Week: <span class="text-danger">*</span></label>
                                                    <select class="form-control" name="livestreamday"
                                                        ref="livestreamday" id="livestreamday"
                                                        v-model="form.livestreamday">
                                                        <option value="0">Sunday</option>
                                                        <option value="1">Monday</option>
                                                        <option value="2">Tuesday</option>
                                                        <option value="3">Wednesday</option>
                                                        <option value="4">Thursday</option>
                                                        <option value="5">Friday</option>
                                                        <option value="6">Saturday</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Hour: <span class="text-danger">*</span></label>
                                                    <select class="form-control" name="livestreamhour"
                                                        ref="livestreamhour" id="livestreamhour"
                                                        v-model="form.livestreamhour">
                                                        <option value="1">1am</option>
                                                        <option value="2">2am</option>
                                                        <option value="3">3am</option>
                                                        <option value="4">4am</option>
                                                        <option value="5">5am</option>
                                                        <option value="6">6am</option>
                                                        <option value="7">7am</option>
                                                        <option value="8">8am</option>
                                                        <option value="9">9am</option>
                                                        <option value="10">10am</option>
                                                        <option value="11">11am</option>
                                                        <option value="12">12pm</option>
                                                        <option value="13">1pm</option>
                                                        <option value="14">2pm</option>
                                                        <option value="15">3pm</option>
                                                        <option value="16">4pm</option>
                                                        <option value="17">5pm</option>
                                                        <option value="18">6pm</option>
                                                        <option value="19">7pm</option>
                                                        <option value="20">8pm</option>
                                                        <option value="21">9pm</option>
                                                        <option value="22">10pm</option>
                                                        <option value="23">11pm</option>
                                                        <option value="24">12am</option>
                                                    </select>
                                                    <span class="form-text text-dark">All times are in Eastern Standard
                                                        Time.</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Minute: <span class="text-danger">*</span></label>
                                                    <select class="form-control" name="livestreamminute"
                                                        ref="livestreamminute" id="livestreamminute"
                                                        v-model="form.livestreamminute">
                                                        <option value="0">00</option>
                                                        <option value="15">15</option>
                                                        <option value="30">30</option>
                                                        <option value="45">45</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Reoccuring: <span class="text-danger">*</span></label>
                                                    <b-form-select v-model="form.livestreamweek" :options="weekoptions"
                                                        class="form-control" multiple name="livestreamweek"
                                                        ref="livestreamweek" :select-size="4"></b-form-select>
                                                    <span class="form-text text-dark">Which weeks in the month does
                                                        this
                                                        reoccur. Hold down cmd key on mac or start/windows key on pc to
                                                        select
                                                        multiple.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <button ref="kt_save_changes" class="btn btn-primary mr-2">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!--end::Form-->
                            </div>
                            <!--end::Card-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import emailAddress from "@/assets/plugins/formvalidation/dist/es6/validators/emailAddress";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
    name: 'coachsignup',
    data() {
        return {
            form: {
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                city: "Denver, CO, USA",
                bio: "",
                description: "",
                title: "",
                category: "",
                video: "https://ingomucdn.s3.amazonaws.com/video/videoplaceholder.m3u8",
                keywords: [],
                profilephoto: null,
                coachphoto: null,
                livestreamtimes: "",
                livestreamday: "",
                livestreamhour: "",
                livestreamminute: "",
                livestreamweek: []
            },
            default_profilephoto: "/media/users/200.png",
            current_profilephoto: null,
            default_coachphoto: "/media/users/371_214.png",
            current_coachphoto: null,
            weekoptions: [
                { value: 1, text: '1st Week' },
                { value: 2, text: '2nd Week' },
                { value: 3, text: '3rd Week' },
                { value: 4, text: '4th Week' },
            ],
            categoryOptions: [],
        };
    },
    mounted() {
        this.$store.dispatch("getCoachCategories");
        const changes_form = KTUtil.getById("kt_save_changes_form");

        this.fv = formValidation(changes_form, {
            fields: {
                firstname: {
                    validators: {
                        notEmpty: {
                            message: "First name is required",
                        },
                    },
                },
                lastname: {
                    validators: {
                        notEmpty: {
                            message: "Last name is required",
                        },
                    },
                },
                email: {
                    validators: {
                        callback: {
                            message: "The value is not a valid email address",
                            callback: function (input) {
                                const value = input.value;
                                if (value === "") {
                                    return true;
                                }

                                // I want the value has to pass both emailAddress and regexp validators
                                return emailAddress().validate({
                                    value: value,
                                }).valid;
                            },
                        },
                        notEmpty: {
                            message: "Email is required",
                        },
                    },
                },
                password: {
                    minLength: minLength(6),
                    validators: {
                        notEmpty: {
                            message: "Password is required",
                        },
                    },
                },
                bio: {
                    validators: {
                        notEmpty: {
                            message: "Bio is required",
                        },
                    },
                },
                description: {
                    validators: {
                        notEmpty: {
                            message: "Description is required",
                        },
                    },
                },
                title: {
                    validators: {
                        notEmpty: {
                            message: "Title is required",
                        },
                    },
                },
                category: {
                    validators: {
                        notEmpty: {
                            message: "Category is required",
                        },
                    },
                },
                livestreamtimes: {
                    validators: {
                        notEmpty: {
                            message: "Livestream time is required",
                        },
                    },
                },
                livestreamday: {
                    validators: {
                        notEmpty: {
                            message: "Livestream day is required",
                        },
                    },
                },
                livestreamhour: {
                    validators: {
                        notEmpty: {
                            message: "Livestream hour is required",
                        },
                    },
                },
                livestreamminute: {
                    validators: {
                        notEmpty: {
                            message: "Livestream minute is required",
                        },
                    },
                },
                livestreamweek: {
                    validators: {
                        notEmpty: {
                            message: "Livestream week is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        let self = this;
        this.fv.on("core.form.valid", () => {
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("createNewCoach", this.form)
                .then(
                    function (value) {
                        if (value == "existing") {
                            Swal.fire({
                                title: "This account already existed so the password will be ignored.",
                                text: "It should take a few minutes to show up in the coaches list.",
                                icon: "success",
                                confirmButtonClass: "btn btn-secondary",
                                heightAuto: true,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    self.$router.push({ name: 'admincoaches' })
                                }
                            })
                        } else if (value == "new") {
                            Swal.fire({
                                title: "",
                                text: "It should take a few minutes to show up in the coaches list.",
                                icon: "success",
                                confirmButtonClass: "btn btn-secondary",
                                heightAuto: true,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    self.$router.push({ name: 'admincoaches' })
                                }
                            })
                        }
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    computed: {
        ...mapGetters(["catList", "categories"]),
        profilephoto() {
            return this.current_profilephoto == null || this.current_profilephoto == "undefined"
                ? this.default_profilephoto
                : this.current_profilephoto;
        },
        coachphoto() {
            return this.current_coachphoto == null || this.current_coachphoto == "undefined"
                ? this.default_coachphoto
                : this.current_coachphoto;
        }
    },
    methods: {
        onProfileFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_profilephoto = event.target.result;
                    this.form.profilephoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        onCoachFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_coachphoto = event.target.result;
                    this.form.coachphoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        }
    },
    watch: {
        categories(newValue) {
            let newOptions = []
            newValue.forEach(element => {
                newOptions.push({ value: element.category, text: element.category })
            })
            this.categoryOptions = newOptions;
        }
    }
}
</script>

<style scoped>
.mt-35 {
    margin-top: 35px !important;
}
</style>